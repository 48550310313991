input[type=submit].btn, 
input[type=button].btn, 
button.btn, 
a.btn {

	display: block;
	color: var(--color-button-default);
	background-color: rgba(255,255,255,0);
	border: 3px solid var(--color-button-default);
	padding: 0.6rem 2rem 0.65rem 2rem;
	cursor: pointer;
	transition: .3s ease all;
	font-size: var(--font-size-forms);
	font-weight: 100;
	
    &:hover{
        color: var(--color-button-default-hover);
        border-color: var(--color-button-default-hover);
    }
    
    &:focus {
        outline: none;
    }
/*    &:disabled {
        @apply pointer-events-none opacity-50;
    }
    &.success {
        background-color: config('colors.green');
        &:hover, &:focus {
            background-color: config('colors.green-dark');
        }
    }
    &.info {
        background-color: config('colors.teal');
        &:hover, &:focus {
            background-color: config('colors.teal-dark');
        }
    }
    &.warning {
        background-color: config('colors.orange');
        &:hover, &:focus {
            background-color: config('colors.orange-dark');
        }
    }
    &.danger {
        background-color: config('colors.red');
        &:hover, &:focus {
            background-color: config('colors.red-dark');
        }
    }
    &.full {
        @apply w-full;
    }
    & + input[type=submit].form, & + input[type=button].form, & + button.form, & + a.form {
        @apply ml-2;
    }
    */
    
    
    &.btn-primary{
       --color-button-default: var(--color-brand);
       --color-button-default-hover: var(--color-brand-hover);

    }
    
    
    &.btn-block{
	    width: 100%;
    }
}
