html {
  box-sizing: border-box;
  height: 100%
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
	font-size: var(--font-size-base);
	font-family: var(--font-family-base);
	color: var(--theme-color--text);
	background: var(--theme-color--background);
	margin: 0;
	padding: 0;
	transition-property: color, background-color;
	transition-duration: .3s;
	transition-timing-function: ease;
}


/*
a{
	color: var(--color-brand);
	text-decoration: none;
	transition: all var(--transition-base);
	&:hover{
		color: var(--color-brand-hover);
	}
}
*/