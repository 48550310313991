/*!
 * Form Framework v1.0.0 (https://form.js.org)
 * Author: Abdalla Arbab.
 * Copyright 2019 Codolog.
 * Licensed under MIT (https://github.com/the94air/form/blob/master/LICENSE)
 */

//@import "./components/link";
//@import "./components/label";
@import "./components/groups";
@import "./components/input";
//@import "./components/textarea";

/*@import "./components/checkbox";
@import "./components/radio";
@import "./components/select";
@import "./components/file";
@import "./components/error";*/
@import "./components/button";











.form-card{
	max-width: 500px;
	margin: var(--spacing-vertical) auto;
	//border: 1px solid #555;
	background: #FFF;
	border-radius: var(--global-border-radius);
	border: 1px solid #b4b4b4;
	padding: var(--spacing-vertical) var(--spacing-horizontal);
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
