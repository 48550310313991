.theme--light{
	--theme-color--text: #000;
	--theme-color--background: #FFF;	
	--theme-color--header: #EEE;
	--theme-color--card-background: #EEE;
	--theme-color--card-text: #000;
}
.theme--dark{
	--theme-color--text: #FFF;
	--theme-color--background: #000;	
	--theme-color--header: #EEE;
	--theme-color--card-background: #EEE;
	--theme-color--card-text: #000;
}