input.form-control:not([type=submit]):not([type=button]):not([type=radio]):not([type=checkbox]):not([type=file]) {

    background-color: var(--color-form-input-background);
    color: var(--color-form);
    padding: .6rem 0;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: var(--color-form-input-border);
    -webkit-appearance: none;
    transition: all 0.2s ease;
    font-size: var(--font-size-forms);
    width: 100%;
    
    
    &:focus {
        border-color: var(--color-form-input-focus);
		border-width: 0 0 3px 0;
		outline: none;
    }
    &:disabled {
		pointer-events: none;
        color: var(--color-form-input-disabled);
        border-color: var(--color-form-input-disabled-border);    
    }
    .error > & {
        color: var(--color-form-input-error);
        border-color: var(--color-form-input-error-border);
   		border-width: 0 0 3px 0;

        &:focus {
            //box-shadow: 0 0 0 3px rgba(227, 52, 47, 0.5);
        }
    }
}




.form-input {
	label{
		font-size: var(--font-size-labels);
        color: var(--color-form-labels);
		font-weight: normal;
		margin-bottom: 5px;
		width: 100%;
		display: block;
		text-align: left;
	}
}