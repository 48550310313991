



/****************************
 *
 *  Page Transition
 *
 ****************************/

/*
.content-wrapper{
	position: relative;
	> .trans{
		position: absolute;
		top:0;
		left: 0;
		right: 0;	
	}

}
*/

.trans{
	transition: opacity 300ms ease-in 300ms;
}


.trans-enter{
	opacity: 0;
}
.trans-enter.trans-enter-active{
	opacity: 1;
}

.trans-exit{
	opacity: 0;
	display: none;
}
.trans-exit.trans-exit-active{
	opacity: 0;
}





/****************************
 *
 *  Type
 *
 ****************************/
h1, h2{
	margin: 0;
	font-weight: 100;
}


/****************************
 *
 *  Menu 
 *
 ****************************/
.navigation{
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 50;
	text-align: right;
}
.topbar{
	background-color: #000;
	position: relative;
	z-index: 5;
	height: var(--topbar-height);

}
.navigation{
	.wrapper{
		background-color: #555;
		z-index: 1;
		position: relative;
		visibility: hidden;
		opacity: 0;
		transition-property: visibility, opacity, max-height, transform;
		transition-duration: .3s;
	//	max-height: 0;
		margin: 0;
		padding: 0;
		transform: translate(-100%,0%);
		height: calc(var(--vh) - var(--topbar-height));
		max-width: 300px;
		width: 100%;
		position: absolute;
		top: var(--topbar-height);
		left: 0;
		nav{
			position: relative;
			margin: 0;
		}
		.navigation-is-open & {
			visibility: visible;
			opacity: 1;
		//	max-height: var(--vh);
			transform: translate(0%,0%);
		}
	}
}
.nav{
	margin: 0px;
	padding: 0;
}

/****************************
 *
 *  card
 *
 ****************************/
 
 .card{
	 margin-top: auto;
	 max-width: 500px;
	 color: var(--theme-color--card-text);
	 background-color: var(--theme-color--card-background);
	 padding: var(--space-base);
 }
 
 
/****************************
 *
 *  Base Layout
 *
 ****************************/
 body{
	 padding-top: var(--topbar-height)
 }
 
 .trans{
	 min-height: calc(var(--vh) - var(--topbar-height));
	 display: flex;
	 justify-content: center;
//	 align-items: center;
 }
 
 
 .content-header{
	padding: var(--space-base);
	background-color: var(--theme-color--header);
}