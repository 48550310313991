:root{
	--font-size-base: 17px;
	--font-family-base: 'Roboto', sans-serif;

	--space-base: 15px;
	
	--topbar-height: 55px;
	

	
	
	
	
	--theme-color--text: #FFF;
	--theme-color--background: #555;	
	--theme-color--header: #666;
	--theme-color--card-background: #FFF;
	--theme-color--card-text: #000;
	
	
	
	--hamburger-layer-color: #EEE;
	--hamburger-active-layer-color: #000;	
	
}
